@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-small {
  font-size: .85rem;
  font-style: normal;
}
.line-break, hr {
  background-color: #f1f1f1;
  border-color: #ccc;
}
input::placeholder {
  font-size: .85rem;
  color: #c2c2c2 !important;
}

label {
  text-transform: capitalize;
}
.secondary-font {
  font-family: 'Open Sans', sans-serif;
}

