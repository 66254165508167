.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  max-width: 150px;
}
.App-Logo-Login {
  max-width: 250px;
}
.App-Inline-Link {
  color: #06191e;
}

.App-Inline-Link__plain {
  text-decoration: none;
}
.App-Main-Nav {
  position: fixed;
  top: 0;
  left: -.75rem;
  border-right: 1px solid #f1f1f1;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  padding-right: 0;
  background-color: #fff;
  transition: left 1s ease-in-out;
}
.App-Nav-link {
  color: #cfcfcf;
  border-bottom:  1px solid #f1f1f1;
  border-right: .6rem solid transparent;
}
.App-Nav-link:hover, .App-Nav-link:hover .App-Nav-Icon-Container {
  color: #545454;
  transition: color .5s ease-in-out;
}

.App-Nav-link:last-child {
  border-bottom: none;
}
.App-Navigation-Active {
  color: #000 !important;
  font-weight: 500;
  border-right-color: #ea3323;
}
.App-Navigation-Active .App-Nav-Icon-Container {
  color: #000;
}
.App-Nav-Section {
  background-color: #000;
  color: #fff;
  line-height: 2.5rem;
}

.App-Input {
  padding-top: 10px;
  padding-bottom: 10px;
}
.App-Input-File {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2.65rem;
}
.App-Thumbnail-Poster {
  max-width: 100%;
}
.App-Thumbnail-Poster__smaller {
  max-height: 80px;
}
.App-Toggle-Switch {
  height: 22.5px !important;
  width: 53px !important;
}
.App-Static-Main {
  min-height: 100vh;
}
.App-Login-Container {
  height: 500px;
  width: 100%;
  max-width: 800px;
}
.App-Login-Wrapper {
  height: 100vh;
}
.App-Fixed-Correction {
  margin-right: -.5rem;
}
.App-Nav-Section-Wrapper {
  visibility: visible;
  opacity: 1;
  transition: all 1s ease-in-out;
}
.App-Main-Content, .App-Fixed-Correction {
  transition: all 1s ease-in-out;
}
.App-Status-Dot {
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: black;
  display: block;
}
.App-Status-Dot__active {
  background-color: #52c052;
}

.App-Status-Dot__inactive {
  background-color: red;
}
.App-Nav-Togggle {
  position: absolute;
  top: 1.5rem;
  right: 1.2rem;
  z-index: 8;
  background: none;
  border: none;
  font-size: 1.5rem;
  transition: right .25s ease-in-out;
}
.App-Collapsed-Menu {
  position: absolute;
  z-index: 5;
  right: -0.5rem;
  width: 0;
  height: 100%;
  top: 6rem;
  overflow: hidden;
  transition: all .5s ease-in-out;
}
.App-Collapsed-Menu nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.App-Collapsed-Menu .App-Nav-Icon-Wrapper {
  margin-bottom: 1rem;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 1rem;
}
.App-Collapsed-Menu .App-Nav-Icon-Wrapper:last-child {
  border-bottom: none;
}

.App-Nav-Icon-Wrapper__link:hover .App-Nav-Icon-Container {
  color: #545454;

}
.App-Menu-Collapsed .App-Fixed-Correction {
  width: 5%;
}
.App-Menu-Collapsed .App-Main-Nav {
  left: -30%;
  transition: all 1s ease-in-out;
}
.App-Menu-Collapsed .App-Main-Nav .App-Nav-Section-Wrapper {
  visibility: hidden;
  opacity: 0;
  transition: all .25s ease-in-out;
}
.App-Menu-Collapsed .App-Main-Content {
  width: 95%;
}
.App-Menu-Collapsed .App-Nav-Togggle {
  right: 0.4rem;
  transition: right .25s ease-in-out;
}
.App-Menu-Collapsed .App-Collapsed-Menu {
  width: 4.5vw;
  transition: all .5s ease-in-out;
}
.App-Nav-Icon-Container {
  font-size: 1.5rem;
  position: relative;
  color: #ddd;
}
.App-Nav-Icon-Container .bi:nth-child(2) {
  position: absolute;
  z-index: 2;
  top: -.25rem;
  right: -.5rem;
  font-size: 1rem;
}
.App-Inactive {
  color: #ccc;
}
.rti--container {
  border: 1px solid rgb(222, 226, 230) !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.responsive-image {
  max-width: 100%;
}
.dashboard-image {
  max-height: 70px;
}
.scale-height-image {
  max-height: 100px;
  width: auto;
}
.uploaded-image-preview {
  max-height: 300px;
}
.App-Page-Title {
  border-bottom: 4px solid #ea3323;
  padding-bottom: .5rem;
  margin-bottom: 1rem;
}
.fourOhfour-image {
  max-height: 400px;
}
.undefined-error-page {
  background-color: #f19e4b;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-button {
  background: none;
  border: none;
}
.nested-row {
  margin: 0 0 0 18px;
}
.label-small {
  font-size: .85rem;
}
.light-icon {
  color: #ccc;
}
.modal-body {
  max-height: 60vh;
  overflow: auto;
}
.fs-small {
  font-size: .85rem;
}

input:disabled {
  color: #c3c3c3;
}
.btn {
  text-transform: capitalize;
}
.border-bottom.App-Flex-Table__row:last-child {
  border-bottom: none !important;
}